define('touch-portal/controllers/internal/user/executions', ['exports', 'touch-portal/mixins/controllers/paginatable', 'touch-portal/mixins/controllers/searchable', 'touch-portal/mixins/controllers/sortable', 'touch-portal/utils/const/execution'], function (exports, _paginatable, _searchable, _sortable, _execution) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_paginatable.default, _searchable.default, _sortable.default, {
    // Controllers
    userController: Ember.inject.controller('internal.user'),
    // Attributes
    queryParams: ['type', 'state', 'fault', 'range'],
    fault: '',
    range: '',
    state: '',
    type: '',
    pageTitle: "Gateway Executions",
    sort: '-insertedAt',
    sortOptions: _execution.SORT_OPTIONS
  });
});