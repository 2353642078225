define('touch-portal/components/search-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // Attributes
    heading: "",
    placeholder: "",
    model: null,
    name: "",
    // Computed
    buttonName: Ember.computed('name', function () {
      return 'search-' + Ember.get(this, 'name');
    }),
    // Actions
    actions: {
      submit: function submit() {
        var action = Ember.get(this, 'onSubmit');
        if (action) {
          action();
        }
      },
      invalid: function invalid() {
        var action = Ember.get(this, 'onInvalid');
        if (action) {
          action();
        }
      }
    }
  });
});