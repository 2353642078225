define('touch-portal/models/portal-user', ['exports', 'ember-data', 'touch-portal/mixins/validations/portal-user'], function (exports, _emberData, _portalUser) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      Model = _emberData.default.Model;
  exports.default = Model.extend(_portalUser.default, {
    // Attributes
    email: attr('string'),
    firstName: attr('string'),
    lastName: attr('string'),
    role: attr('string'),
    token: attr('string'),
    password: attr('string'),
    // Relationships
    // gateway: belongsTo(),
    // Computed
    name: Ember.computed('firstName', 'lastName', function () {
      return Ember.get(this, 'firstName') + ' ' + Ember.get(this, 'lastName');
    }),
    avatar: Ember.computed(function () {
      return 'http://api.faceholder.io/faces?id=' + Ember.get(this, 'id');
    })
  });
});