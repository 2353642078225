define('touch-portal/mirage/config', ['exports', 'touch-portal/mirage/helpers/session-handlers', 'touch-portal/mirage/helpers/invite-handlers', 'touch-portal/mirage/helpers/responses', 'touch-portal/config/environment'], function (exports, _sessionHandlers, _inviteHandlers, _responses, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    var _this = this;

    // make this `http://localhost:8080`, for example, if your API is on a different server
    this.urlPrefix = _environment.default.rootApiURL; //'http://localhost:4300';
    // make this `api`, for example, if your API is namespaced
    this.namespace = 'api/v1/admin';
    // delay for each request, automatically set to 0 during testing
    this.timing = 400;

    // Authentication
    this.get('/sessions', _sessionHandlers.getSession);
    this.post('/sessions', _sessionHandlers.postSession);
    this.delete('/sessions', _sessionHandlers.deleteSession);

    // Passwords
    this.post('/recover-password', function () {
      return (0, _responses.successResponse)(202);
    });
    this.put('/reset-password', function () {
      return (0, _responses.successResponse)(204);
    });

    this.put('/update-password', function () {
      return (0, _responses.successResponse)(204);
    });
    this.put('/invitations', _inviteHandlers.putInvite);

    // Basic Data
    this.get('/basic-system-infos');

    // Users
    this.get('/users');
    this.post('/users');
    this.get('/users/:id');
    this.put('/users/:id');
    this.patch('/users/:id');
    this.delete('/users/:id');

    // Users
    this.get('/portal-users');
    this.post('/portal-users');
    this.get('/portal-users/:id');
    this.put('/portal-users/:id');
    this.patch('/portal-users/:id');
    this.delete('/portal-users/:id');

    // Help
    this.get('/help-items');
    this.post('/help-items');
    this.get('/help-items/:id');
    this.put('/help-items/:id');
    this.patch('/help-items/:id');
    this.delete('/help-items/:id');

    // Redirects
    this.get('/link-redirects');
    this.post('/link-redirects');
    this.get('/link-redirects/:id');
    this.put('/link-redirects/:id');
    this.patch('/link-redirects/:id');
    this.delete('/link-redirects/:id');

    // Gateways
    this.get('/gateways');
    this.get('/gateways/:id');
    this.delete('/gateways/:id');
    this.post('/gateway/reset');

    // Boiler Faults
    this.get('/historic-boiler-faults');
    this.get('/historic-boiler-faults/:id');

    // Gateway Executions
    this.get('/executions');
    this.get('/executions/:id');

    // Set up environmental
    if (_environment.default['ember-cli-mirage'].passthroughs) {
      _environment.default['ember-cli-mirage'].passthroughs.forEach(function (passthrough) {
        _this.passthrough(passthrough);
      });
    }
  };
});