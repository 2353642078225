define('touch-portal/mirage/factories/help-item', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    title: function title() {
      return _emberCliMirage.faker.lorem.words();
    },
    message: function message() {
      return _emberCliMirage.faker.lorem.paragraphs(3);
    },

    asLink: (0, _emberCliMirage.trait)({
      link: function link() {
        return _emberCliMirage.faker.internet.url();
      }
    }),
    asVideo: (0, _emberCliMirage.trait)({
      video: function video() {
        return _emberCliMirage.faker.internet.url();
      }
    })
  });
});