define('touch-portal/components/help-item-actions', ['exports', 'ember-can'], function (exports, _emberCan) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_emberCan.CanMixin, {
    // Attributes
    tagName: '',

    // Computed
    items: Ember.computed('_items', 'model.gateway.id', function () {
      var _this = this;

      var items = Ember.get(this, '_items'),
          array = [];

      items.forEach(function (_item) {
        var item = Ember.copy(_item),
            ability = Ember.get(item, 'ability');

        if (ability) {
          if (_this.can(Ember.get(item, 'ability'))) {
            array.pushObject(item);
          }
        } else {
          array.pushObject(item);
        }
      });

      return array;
    }),
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      Ember.set(this, '_items', [{
        title: 'View Help Item',
        route: 'internal.help-item.index',
        ability: 'read help-item'
      }, {
        title: 'Edit Help Item',
        route: 'internal.help-item.edit',
        ability: 'update help-item'
      }, {
        title: 'Archive Help Item',
        route: 'internal.help-item.archive',
        ability: 'archive help-item'
      }]);
    }
  });
});