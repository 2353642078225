define('touch-portal/components/uxs-date-range/day', ['exports', 'ember-ux-date-sauce/components/uxs-date-range/day'], function (exports, _day) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _day.default;
    }
  });
});