define('touch-portal/controllers/internal/account/password', ['exports', 'touch-portal/utils/get-api-url'], function (exports, _getApiUrl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    // Service
    toast: Ember.inject.service(),
    // Attributes
    pageTitle: "Account Password",
    // Actions
    actions: {
      save: function save(model) {
        var toast = Ember.get(this, 'toast'),
            data = JSON.stringify({
          data: {
            type: 'update-password',
            attributes: {
              current_password: model.get('oldPassword'),
              password: model.get('password'),
              password_confirmation: model.get('passwordConfirmation')
            }
          }
        }),
            url = (0, _getApiUrl.default)('/update-password');

        Ember.$.ajax({
          type: 'PUT',
          url: url,
          accept: 'application/vnd.api+json',
          contentType: 'application/vnd.api+json',
          data: data,
          success: function success() {
            toast.info('Password updated');
          },
          error: function error(jqXHR, textStatus) {
            if (textStatus === 0) {
              toast.info('Failed to connect to server, please try again.');
            } else {
              toast.info('Error resetting your password, please check your current password.');
            }
          }
        });
      },
      invalid: function invalid() /*model, errors*/{
        var toast = Ember.get(this, 'toast');
        toast.info('Please correct form errors');
      }
    }
  });
});