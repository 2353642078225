define('touch-portal/helpers/bem', ['exports', 'ember-bem-sauce/helpers/bem'], function (exports, _bem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _bem.default;
    }
  });
  Object.defineProperty(exports, 'bem', {
    enumerable: true,
    get: function () {
      return _bem.bem;
    }
  });
});