define('touch-portal/controllers/internal/user/impersonate', ['exports', 'touch-portal/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    // Service
    toast: Ember.inject.service(),
    store: Ember.inject.service(),
    cookies: Ember.inject.service(),
    // Attributes
    pageTitle: 'Impersonate Customer',

    getHostName: function getHostName(url) {
      var match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
      if (match != null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
        return match[2];
      } else {
        return null;
      }
    },

    extractDomainFromUrl: function extractDomainFromUrl(url) {
      var hostName = this.getHostName(url);
      var domain = hostName;

      if (hostName != null) {
        var parts = hostName.split('.').reverse();

        if (parts != null && parts.length > 1) {
          domain = parts[1] + '.' + parts[0];

          if (hostName.toLowerCase().indexOf('.co.uk') != -1 && parts.length > 2) {
            domain = parts[2] + '.' + domain;
          }
        }
      }

      return domain;
    },

    // Actions
    actions: {
      impersonate: function impersonate(model) {
        var toast = Ember.get(this, 'toast');
        var store = Ember.get(this, 'store');
        var cookieService = Ember.get(this, 'cookies');
        var webAppURL = _environment.default.webAppURL;
        var cookieDomain = this.extractDomainFromUrl(webAppURL);

        var impersonation = store.createRecord('impersonation', {
          user: model
        });

        impersonation.save().then(function (impersonation) {
          cookieService.write('impersonate_user', model.get('id'), {
            domain: cookieDomain,
            path: '/',
            maxAge: 900
          });
          cookieService.write('impersonate_token', impersonation.get('token'), {
            domain: cookieDomain,
            path: '/',
            maxAge: 900
          });
          cookieService.write('impersonate_gateway', model.get('gateway.id'), {
            domain: cookieDomain,
            path: '/',
            maxAge: 900
          });
          window.open(webAppURL);
          toast.info('Open Touch');
        }, function () /*error*/{
          toast.info('Server Error');
        });
      },
      cancel: function cancel(model) {
        return this.transitionToRoute('internal.user.index', model.id);
      }
    }
  });
});