define('touch-portal/tests/mirage/mirage.lint-test', [], function () {
  'use strict';

  QUnit.module('ESLint | mirage');

  QUnit.test('mirage/config.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/config.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/basic-system-info.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/basic-system-info.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/execution.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/execution.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/gateway.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/gateway.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/help-item.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/help-item.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/historic-boiler-fault.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/historic-boiler-fault.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/link-redirect.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/link-redirect.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/portal-user.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/portal-user.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/user.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/user.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/fixtures/portal-users.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/fixtures/portal-users.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/helpers/invite-handlers.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/helpers/invite-handlers.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/helpers/responses.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/helpers/responses.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/helpers/session-handlers.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/helpers/session-handlers.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/boiler-fault.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/boiler-fault.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/execution.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/execution.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/gateway.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/gateway.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/help-item.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/help-item.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/link-redirect.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/link-redirect.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/portal-user.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/portal-user.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/user.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/user.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/scenarios/default.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/scenarios/default.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/application.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/application.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/link-redirect.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/link-redirect.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/portal-user.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/portal-user.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/user.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/user.js should pass ESLint\n\n');
  });
});