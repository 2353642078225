define('touch-portal/components/link-mapper-actions', ['exports', 'ember-can'], function (exports, _emberCan) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_emberCan.CanMixin, {
    // Attributes
    tagName: '',
    // Computed
    items: Ember.computed('_items', 'model.id', function () {
      var _this = this;

      var items = Ember.get(this, '_items'),
          hasModel = !!Ember.get(this, 'model.id'),
          array = [];

      items.forEach(function (_item) {
        var item = Ember.copy(_item),
            ability = Ember.get(item, 'ability');

        item.disabled = false;

        if (!hasModel) {
          item.disabled = true;
        }

        if (ability) {
          if (_this.can(Ember.get(item, 'ability'))) {
            array.pushObject(item);
          }
        } else {
          array.pushObject(item);
        }
      });

      return array;
    }),
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      Ember.set(this, '_items', [{
        title: 'View Link Map',
        route: 'internal.link-mapper.index',
        name: 'view'
      }, {
        title: 'Edit Link Map',
        route: 'internal.link-mapper.edit',
        name: 'edit'
      }, {
        title: 'Archive Link Map',
        route: 'internal.link-mapper.archive',
        name: 'archive'
      }]);
    }
  });
});