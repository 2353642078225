define('touch-portal/components/boiler-state/item', ['exports', 'ember-bem-sauce/mixins/bem-component'], function (exports, _bemComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bemComponent.default, {
    // Attributes
    base: 'boiler-state__button',
    // Computed
    isActive: Ember.computed('active', 'state', function () {
      return Ember.get(this, 'active') === Ember.get(this, 'state');
    }),
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifier('isActive:active');
    }
  });
});