define('touch-portal/controllers/internal/users/index', ['exports', 'touch-portal/mixins/controllers/paginatable', 'touch-portal/mixins/controllers/searchable', 'touch-portal/mixins/controllers/sortable', 'touch-portal/utils/const/user'], function (exports, _paginatable, _searchable, _sortable, _user) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_paginatable.default, _searchable.default, _sortable.default, {
    pageTitle: 'Users',
    queryParams: ['postcode', 'search', 'serial'],
    postcode: '',
    search: '',
    serial: '',
    sort: 'lastName',
    sortOptions: _user.SORT_OPTIONS,
    // Computed
    hasFilter: Ember.computed.or('search', 'serial', 'postcode'),
    hasNoFilter: Ember.computed.not('hasFilter'),
    // Action
    actions: {
      clearFilters: function clearFilters() {
        Ember.set(this, 'search', '');
        Ember.set(this, 'serial', '');
        Ember.set(this, 'postcode', '');
      }
    }
  });
});