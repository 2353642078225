define('touch-portal/models/reset-password', ['exports', 'ember-data', 'touch-portal/mixins/validations/reset-password'], function (exports, _emberData, _resetPassword) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      Model = _emberData.default.Model;
  exports.default = Model.extend(_resetPassword.default, {
    recoveryToken: attr('string'),
    password: attr('string')
  });
});