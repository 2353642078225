define('touch-portal/routes/internal/logout', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    // Service
    toast: Ember.inject.service(),
    // Methods
    activate: function activate() {
      var _this = this;

      // Show message and then logout
      Ember.run.later(function () {

        var toast = Ember.get(_this, 'toast'),
            session = Ember.get(_this, 'session');

        session.close().then(function () {
          toast.info('Goodbye…');
          _this.transitionTo('external.login');
        });
      }, 2000);
    }
  });
});