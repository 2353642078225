define('touch-portal/mixins/routes/rollbackable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    deactivate: function deactivate() {
      this.rollback(this.currentModel);
    },
    // Actions
    actions: {
      willTransition: function willTransition() /*transition*/{
        var model = this.modelFor(this.routeName);
        this.rollback(model);
      }
    },
    rollback: function rollback(model) {
      model.rollbackAttributes();
    }
  });
});