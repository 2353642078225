define('touch-portal/abilities/historic-boiler-fault', ['exports', 'ember-can', 'touch-portal/utils/const/portal-user'], function (exports, _emberCan, _portalUser) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCan.Ability.extend({
    // Service
    session: Ember.inject.service('session'),
    user: Ember.computed.alias('session.currentUser'),
    // Computed
    isAdminOrServiceUser: Ember.computed.or('user.role', _portalUser.ADMIN_ROLE, _portalUser.SERVICE_ROLE),
    // only admins and service users can read a boiler fault
    canRead: Ember.computed.alias('isAdminOrServiceUser')
  });
});