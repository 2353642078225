define('touch-portal/utils/const/portal-user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ADMIN_ROLE = exports.ADMIN_ROLE = 'admin';
  var SERVICE_ROLE = exports.SERVICE_ROLE = 'service';
  var ROLE_OPTIONS = exports.ROLE_OPTIONS = [ADMIN_ROLE, SERVICE_ROLE];
  var SORT_OPTIONS = exports.SORT_OPTIONS = [{
    label: 'Email',
    value: 'email'
  }, {
    label: 'First Name',
    value: 'firstName'
  }, {
    label: 'Surname',
    value: 'lastName'
  }, {
    label: 'Role',
    value: 'role'
  }];
});