define('touch-portal/router', ['exports', 'touch-portal/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('external', {
      path: 'auth'
    }, function () {
      this.route('login');
      this.route('invite', {
        path: 'invite/:token'
      });
      this.route('recover-password', {
        path: 'recover'
      });
      this.route('reset-password', {
        path: 'reset/:token'
      });
    });

    this.authenticatedRoute('internal', {
      path: '/'
    }, function () {
      this.route('dashboard', {
        path: '/'
      });

      this.route('account', function () {
        this.route('password');
      });

      this.route('users', function () {
        this.route('index', {
          path: '/'
        });
        // this.route('new');
      });
      this.route('user', {
        path: 'user/:user_id'
      }, function () {
        this.route('edit');
        this.route('gateway');
        this.route('faults');
        this.route('executions');
        this.route('deregister');
        this.route('resync');
        this.route('refresh-devices');
        this.route('impersonate');
        this.route('archive');
      });

      this.route('help-items', function () {
        this.route('new');
      });
      this.route('help-item', {
        path: 'help-item/:help_item_id'
      }, function () {
        this.route('edit');
        this.route('archive');
      });

      this.route('link-mappers', function () {
        this.route('new');
      });
      this.route('link-mapper', {
        path: 'link-mapper/:link_redirect_id'
      }, function () {
        this.route('edit');
        this.route('archive');
      });

      this.route('portal-users', function () {
        this.route('new');
      });
      this.route('portal-user', {
        path: 'portal-user/:user_id'
      }, function () {
        this.route('edit');
        this.route('archive');
      });
      this.route('logout');
    });

    this.route('not-found', {
      path: '/*path'
    });
  });

  exports.default = Router;
});