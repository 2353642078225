define('touch-portal/routes/internal/user/deregister', ['exports', 'touch-portal/mixins/routes/authorisationable'], function (exports, _authorisationable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authorisationable.default, {
    // Attributes
    ability: 'deregister gateway'
  });
});