define('touch-portal/mirage/factories/historic-boiler-fault', ['exports', 'ember-cli-mirage', 'touch-portal/utils/const/historic-boiler-fault'], function (exports, _emberCliMirage, _historicBoilerFault) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    message: function message() {
      return _emberCliMirage.faker.random.arrayElement(["Can't find gateway", "No internet connection", "Boiler pressure low", "Gatway not responding", "Boiler not found"]);
    },
    boilerFaultCode: function boilerFaultCode() {
      return _emberCliMirage.faker.random.number({
        max: 20
      });
    },
    faultType: function faultType() {
      return _emberCliMirage.faker.random.arrayElement(_historicBoilerFault.BOILER_FAULT_TYPES);
    },
    insertedAt: function insertedAt() {
      return _emberCliMirage.faker.date.recent(7);
    },

    withGateway: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(fault, server) {
        var gateways = server.schema.gateways.all().models,
            gateway = _emberCliMirage.faker.random.arrayElement(gateways);

        fault.update({
          gateway: gateway
        });
      }
    })
  });
});