define('touch-portal/controllers/internal/user/faults', ['exports', 'touch-portal/mixins/controllers/paginatable', 'touch-portal/mixins/controllers/searchable', 'touch-portal/mixins/controllers/sortable', 'touch-portal/utils/const/historic-boiler-fault'], function (exports, _paginatable, _searchable, _sortable, _historicBoilerFault) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_paginatable.default, _searchable.default, _sortable.default, {
    // Controllers
    userController: Ember.inject.controller('internal.user'),
    // Attributes
    queryParams: ['type', 'code', 'range'],
    type: '',
    code: '',
    range: '',
    pageTitle: "Boiler Fault History",
    sort: '-insertedAt',
    sortOptions: _historicBoilerFault.SORT_OPTIONS
  });
});