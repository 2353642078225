define('touch-portal/components/dash-badge', ['exports', 'ember-bem-sauce/mixins/bem-component'], function (exports, _bemComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bemComponent.default, {
    base: 'badge',
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifiers(['*style']);
    }
  });
});