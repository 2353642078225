define('touch-portal/controllers/internal', ['exports', 'touch-portal/config/environment', 'ember-can'], function (exports, _environment, _emberCan) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_emberCan.CanMixin, {
    // Attributes
    name: _environment.default.APP.name,
    // Computed
    menuItems: Ember.computed('_menuItems', function () {
      var _this = this;

      var items = [];
      Ember.get(this, '_menuItems').filter(function (item) {
        var ability = Ember.get(item, 'ability');
        if (ability) {
          if (_this.can(Ember.get(item, 'ability'))) {
            items.pushObject(item);
          }
        } else {
          items.pushObject(item);
        }
      });
      return items;
    }),
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      Ember.set(this, '_menuItems', [{
        label: "Dashboard",
        route: "internal.dashboard"
      }, {
        label: "Customers",
        route: "internal.users",
        currentWhen: "internal.users internal.user",
        ability: 'read user'
      }, {
        label: "Ideal Users",
        route: "internal.portal-users",
        currentWhen: "internal.portal-users internal.portal-user",
        ability: 'read portal-user'
      }, {
        label: "Help",
        route: "internal.help-items",
        currentWhen: "internal.help-items internal.help-item",
        ability: 'read help-item'
      }, {
        label: "Link Maps",
        route: "internal.link-mappers",
        currentWhen: "internal.link-mappers internal.link-mapper",
        ability: 'read link-map'
      }, {
        label: "Account",
        route: "internal.account"
      }, {
        label: "Logout",
        route: "internal.logout"
      }]);
    }
  });
});