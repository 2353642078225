define('touch-portal/mirage/scenarios/default', ['exports', 'touch-portal/utils/const/portal-user'], function (exports, _portalUser) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (server) {

    server.create('portal-user', {
      firstName: 'Matt',
      lastName: 'Gibson',
      email: 'matt@wearesauce.io',
      role: _portalUser.SERVICE_ROLE
    });
    server.create('portal-user', {
      firstName: 'Jim',
      lastName: 'Wardlaw',
      email: 'jim@wearesauce.io',
      role: _portalUser.ADMIN_ROLE
    });
    server.create('portal-user', {
      firstName: 'Jonathan',
      lastName: 'Wilkinson',
      email: 'jonathan.wilkinson@groupe-atlantic.co.uk',
      role: _portalUser.ADMIN_ROLE
    });

    server.createList('portal-user', 20);
    server.createList('user', 10);
    server.createList('user', 20, 'withGatewayWithCurrentFaults');
    server.createList('user', 20, 'withGateway');
    server.createList('boiler-fault', 50, 'withGateway');

    server.createList('execution', 200, 'asCompletedExecution', 'withGateway');
    server.createList('execution', 50, 'asFailedExecution', 'withGateway');
    server.createList('execution', 50, 'asRunningExecution', 'withGateway');

    server.createList('help-item', 10);
    server.createList('help-item', 15, 'asVideo');
    server.createList('help-item', 25, 'asLink');

    server.createList('link-redirect', 50);

    server.create('basic-system-info');

    var gateway = server.create('gateway', {
      serial: '1111-1111-1111'
    });
    server.create('user', {
      gateway: gateway,
      firstName: 'Jim',
      lastName: 'Wardlaw',
      name: 'Jim Wardlaw'
    });
  };
});