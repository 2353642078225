define('touch-portal/helpers/pad', ['exports', 'ember-pad/helpers/pad'], function (exports, _pad) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _pad.default;
    }
  });
  Object.defineProperty(exports, 'pad', {
    enumerable: true,
    get: function () {
      return _pad.pad;
    }
  });
});