define('touch-portal/mirage/fixtures/portal-users', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = [{
    firstName: 'Matt',
    lastName: 'Gibson',
    email: 'matt@wearesauce.io',
    role: 'Admin'
  }, {
    firstName: 'John',
    lastName: 'Polling',
    email: 'john@wearesauce.io',
    role: 'Admin'
  }, {
    firstName: 'Jim',
    lastName: 'Wardlaw',
    email: 'jim@wearesauce.io',
    role: 'Admin'
  }, {
    firstName: 'Matt',
    lastName: 'Weldon',
    email: 'matt.weldon@wearesauce.io',
    role: 'Admin'
  }, {
    firstName: 'Jonathan',
    lastName: 'Wilkinson',
    email: 'jonathan.wilkinson@groupe-atlantic.co.uk',
    role: 'Admin'
  }];
});