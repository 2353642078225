define('touch-portal/controllers/internal/portal-users/index', ['exports', 'touch-portal/mixins/controllers/paginatable', 'touch-portal/mixins/controllers/searchable', 'touch-portal/mixins/controllers/sortable', 'touch-portal/utils/const/portal-user'], function (exports, _paginatable, _searchable, _sortable, _portalUser) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_paginatable.default, _searchable.default, _sortable.default, {
    pageTitle: 'Ideal Users',
    queryParams: ['role'],
    role: null,
    sort: 'lastName',
    sortOptions: _portalUser.SORT_OPTIONS,
    // Computed
    hasFilter: Ember.computed.or('role', 'search'),
    hasNoFilter: Ember.computed.not('hasFilter'),
    // Action
    actions: {
      clearFilters: function clearFilters() {
        Ember.set(this, 'search', '');
        Ember.set(this, 'role', '');
      }
    }
  });
});