define('touch-portal/controllers/internal/link-mapper/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    // Service
    toast: Ember.inject.service(),
    // Attributes
    pageTitle: "Update Link Map",
    // Actions
    actions: {
      submit: function submit(model) {
        var toast = Ember.get(this, 'toast');

        model.save().then(function () {
          toast.info('Link Map Updated');
        }, function () {
          toast.info('Server Error');
        });
      },
      invalid: function invalid() /*model, errors*/{
        var toast = Ember.get(this, 'toast');
        toast.info('Please correct form errors');
      }
    }
  });
});