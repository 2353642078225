define('touch-portal/components/summary-card', ['exports', 'ember-bem-sauce/mixins/bem-component'], function (exports, _bemComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bemComponent.default, {
    // Attributes
    base: 'card',
    // Computed
    hasHeading: Ember.computed.bool('heading'),
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifiers(['headingOnly:heading-only']);
    }
  });
});