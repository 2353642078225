define('touch-portal/controllers/internal/user/deregister', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    // Service
    toast: Ember.inject.service(),
    store: Ember.inject.service(),
    // Attributes
    pageTitle: 'Deregister Gateway',
    // Actions
    actions: {
      deregister: function deregister(user) {
        var _this = this;

        var toast = Ember.get(this, 'toast');
        var gatewayRecord = this.store.peekRecord('gateway', user.get('gateway.id'));

        gatewayRecord.destroyRecord().then(function () {
          toast.info('Gateway Deregistered');
          return _this.transitionToRoute('internal.user.index', user.id);
        }, function () {
          toast.info('Server Error');
        });
      },
      cancel: function cancel(model) {
        return this.transitionToRoute('internal.user.index', model.id);
      }
    }
  });
});