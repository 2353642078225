define('touch-portal/utils/const/user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var SORT_OPTIONS = exports.SORT_OPTIONS = [{
    label: 'Email',
    value: 'email'
  }, {
    label: 'First Name',
    value: 'firstName'
  }, {
    label: 'Surname',
    value: 'lastName'
  }, {
    label: 'Last Seen',
    value: 'lastLoggedInAt'
  }];
});