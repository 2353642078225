define('touch-portal/components/uxs-table', ['exports', 'ember-ux-sauce/components/uxs-table'], function (exports, _uxsTable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _uxsTable.default;
    }
  });
});