define('touch-portal/routes/internal/user/executions', ['exports', 'touch-portal/mixins/routes/authorisationable', 'touch-portal/mixins/routes/paginatable', 'touch-portal/mixins/routes/searchable', 'touch-portal/mixins/routes/sortable', 'touch-portal/utils/remove-empty-attributes', 'ember-ux-date-sauce/utils/format-to-range'], function (exports, _authorisationable, _paginatable, _searchable, _sortable, _removeEmptyAttributes, _formatToRange) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authorisationable.default, _paginatable.default, _searchable.default, _sortable.default, {
    // Attributes
    ability: 'read execution',
    queryParams: {
      type: {
        refreshModel: true
      },
      state: {
        refreshModel: true
      },
      fault: {
        refreshModel: true
      },
      range: {
        refreshModel: true
      }
    },
    // Methods
    getFilterParams: function getFilterParams(params) {
      if (!Ember.isEmpty(params.range)) {
        var range = (0, _formatToRange.default)(params.range);
        params.from = range[0].format('YYYY-MM-DD');
        params.to = range[1].format('YYYY-MM-DD');
        delete params.range;
      }
      if (!Ember.isEmpty(params.fault)) {
        params.failureCode = params.fault;
        delete params.fault;
      }
      if (!Ember.isEmpty(params.type)) {
        params.eventType = params.type;
        delete params.type;
      }

      params = (0, _removeEmptyAttributes.default)(params);

      return params;
    },
    model: function model(params) {

      var user = this.modelFor('internal.user'),
          sort = this.getSortParam(params),
          page = this.getPageParams(params),
          filter = this.getFilterParams(params);

      filter.gatewayId = Ember.get(user, 'gateway.id');

      return this.store.query('execution', {
        filter: filter,
        page: page,
        sort: sort,
        include: 'gateway'
      });
    }
  });
});