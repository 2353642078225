define('touch-portal/components/temperature-hero', ['exports', 'ember-bem-sauce/mixins/bem-component', 'touch-portal/utils/temp-to-class-modifier'], function (exports, _bemComponent, _tempToClassModifier) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bemComponent.default, {
    // Attributes
    base: 'temperature-hero',
    // Computed
    feels: Ember.computed('model.devicesData.central-heating-target-temperature', function () {
      return (0, _tempToClassModifier.default)(Ember.get(this, 'model.devicesData.central-heating-target-temperature'));
    }),
    showFlame: Ember.computed.bool('model.devicesData.flame-symbol'),
    showTimer: Ember.computed('model.devicesData.central-heating-display-mode', function () {
      var mode = Ember.get(this, 'model.devicesData.central-heating-display-mode');
      return mode === 'timer' || mode === 'next';
    }),
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifiers(['card', '*feels']);
    }
  });
});