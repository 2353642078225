define('touch-portal/routes/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    actions: {
      accessDenied: function accessDenied(transition) {
        // set the failed transition on login controller
        // so we can redirect the user back there after log in
        var loginController = this.controllerFor('external.login');
        loginController.set('previousTransition', transition);
        // See ya
        return this.transitionTo('external.login');
      }
    }
  });
});