define('touch-portal/helpers/to-temperature', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatTemp = formatTemp;
  function formatTemp(params /*, hash*/) {

    var value = params[0],
        decimalPlaces = 1;

    if (params.length > 1) {
      decimalPlaces = params[1];
    }

    if (value == null) {
      return '--.-';
    } else {
      if (value <= 0) {
        return '--.-';
      } else {
        return (Math.round(parseFloat(value) * 2) / 2).toFixed(decimalPlaces);
      }
    }
  }

  exports.default = Ember.Helper.helper(formatTemp);
});