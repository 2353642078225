define('touch-portal/models/help-item', ['exports', 'ember-data', 'touch-portal/mixins/validations/help-item'], function (exports, _emberData, _helpItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      Model = _emberData.default.Model;
  exports.default = Model.extend(_helpItem.default, {
    title: attr('string'),
    message: attr('string'),
    video: attr('string'),
    link: attr('string'),
    // Computeds
    isVideo: Ember.computed.bool('video'),
    isLink: Ember.computed.bool('link')
  });
});