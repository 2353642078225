define('touch-portal/components/uxs-date-range/quarter', ['exports', 'ember-ux-date-sauce/components/uxs-date-range/quarter'], function (exports, _quarter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _quarter.default;
    }
  });
});