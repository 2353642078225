define('touch-portal/models/basic-system-info', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      Model = _emberData.default.Model;
  exports.default = Model.extend({
    // Attributes
    registeredUsersCount: attr('number'),
    onlineGatewaysCount: attr('number'),
    offlineGatewaysCount: attr('number')
  });
});