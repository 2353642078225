define('touch-portal/routes/internal/dashboard', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.store.query('basic-system-info', {}).then(function (data) {
        return data.get('firstObject');
      });
    }
  });
});