define('touch-portal/abilities/portal-user', ['exports', 'ember-can', 'touch-portal/utils/const/portal-user'], function (exports, _emberCan, _portalUser) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCan.Ability.extend({
    // Service
    session: Ember.inject.service('session'),
    user: Ember.computed.alias('session.currentUser'),
    // Computed
    isAdmin: Ember.computed.equal('user.role', _portalUser.ADMIN_ROLE),
    // only admins can create an ideal user
    canCreate: Ember.computed.alias('isAdmin'),
    // only admins can read an ideal user
    canRead: Ember.computed.alias('isAdmin'),
    // only admins can update an ideal user
    canUpdate: Ember.computed.alias('isAdmin'),
    // only admins can archive an ideal user
    canArchive: Ember.computed.alias('isAdmin')
  });
});