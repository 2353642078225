define('touch-portal/controllers/external/login', ['exports', 'touch-portal/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    // Service
    toast: Ember.inject.service(),
    // Attributes
    name: _environment.default.APP.name,
    // Actions
    actions: {
      login: function login(auth) {
        var _this = this;

        var toast = Ember.get(this, 'toast'),
            session = Ember.get(this, 'session');

        session.open('jsonapi', auth).then(function (response) {
          var user = response.currentUser,
              previousTransition = Ember.get(_this, 'previousTransition');

          toast.info('Welcome back ' + user.get('firstName'));

          // Log the user in, then reattempt previous transition if it exists.
          if (previousTransition) {
            Ember.set(_this, 'previousTransition', null);
            previousTransition.retry();
          } else {
            // Default back to homepage
            _this.transitionToRoute('internal.dashboard');
          }
        }, function (rejectReason) {
          if (rejectReason.status === 0) {
            toast.info('Failed to connect to server, please try again.');
          } else {
            toast.info('Login details are incorrect.');
          }
        });
      },
      invalid: function invalid() {
        var toast = Ember.get(this, 'toast');
        toast.info('Please correct form errors');
      }
    }
  });
});