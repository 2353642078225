define('touch-portal/components/boiler-state', ['exports', 'ember-bem-sauce/mixins/bem-component', 'touch-portal/utils/const/gateway'], function (exports, _bemComponent, _gateway) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bemComponent.default, {
    base: 'boiler-state',
    states: _gateway.CENTRAL_HEATING_STATES
  });
});