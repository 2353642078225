define('touch-portal/utils/const/help-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var HELP_OPTIONS = exports.HELP_OPTIONS = ['Video', 'Link'];
  var SORT_OPTIONS = exports.SORT_OPTIONS = [{
    label: 'Title',
    value: 'title'
  }, {
    label: 'Created',
    value: 'inserted-at'
  }, {
    label: 'Updated',
    value: 'updated-at'
  }];
});