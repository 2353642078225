define('touch-portal/models/historic-boiler-fault', ['exports', 'ember-data', 'touch-portal/utils/const/historic-boiler-fault'], function (exports, _emberData, _historicBoilerFault) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      Model = _emberData.default.Model;
  exports.default = Model.extend({
    // Attributes
    message: attr('string'),
    boilerFaultCode: attr('string'),
    faultType: attr('string'),
    insertedAt: attr('date'),
    // Relationships
    gateway: belongsTo(),
    // Computed
    faultTypeStyle: Ember.computed('faultType', function () {
      var type = Ember.get(this, 'faultType');

      if (type === _historicBoilerFault.HARDWARE_FAULT) {
        return 'warning';
      }
      if (type === _historicBoilerFault.BOILER_FAULT) {
        return 'error';
      }
    })
  });
});