define('touch-portal/routes/internal/users/index', ['exports', 'touch-portal/mixins/routes/authorisationable', 'touch-portal/mixins/routes/paginatable', 'touch-portal/mixins/routes/searchable', 'touch-portal/mixins/routes/sortable'], function (exports, _authorisationable, _paginatable, _searchable, _sortable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authorisationable.default, _paginatable.default, _searchable.default, _sortable.default, {
    // Attributes
    ability: 'read user',
    queryParams: {
      postcode: {
        refreshModel: true
      },
      serial: {
        refreshModel: true
      }
    },
    // Methods
    model: function model(params) {
      var sort = this.getSortParam(params),
          page = this.getPageParams(params);

      return this.store.query('user', {
        filter: params,
        include: 'gateway',
        page: page,
        sort: sort
      });
    },

    /**
     * When the controller loads we want to check if the route
     * has any filters set. If it doesnt we will show the filter
     * form by default
     */
    setupController: function setupController(controller, post) {
      this._super(controller, post);
      // is entering the route
      if (controller.get('hasNoFilter')) {
        controller.set('showFilter', true);
      }
    }
  });
});