define('touch-portal/components/countdown-timer', ['exports', 'ember-bem-sauce/mixins/bem-component', 'moment', 'ember-pad/utils/pad'], function (exports, _bemComponent, _moment, _pad) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bemComponent.default, {
    // Attributes
    base: 'timer',
    // Computed
    countdownHours: Ember.computed('secondsToGo', function () {
      var secondsToGo = this.roundUpToNearestMinute(Ember.get(this, 'secondsToGo')),
          hours = Math.floor(secondsToGo / 3600);
      return (0, _pad.padStart)(hours, 2);
    }),
    countdownMinutes: Ember.computed('secondsToGo', function () {
      var secondsToGo = this.roundUpToNearestMinute(Ember.get(this, 'secondsToGo')),
          minutes = Math.floor(secondsToGo % 3600 / 60);

      if (secondsToGo < 60) {
        return '01';
      }

      return (0, _pad.padStart)(minutes, 2);
    }),
    countdownString: Ember.computed('secondsToGo', function () {
      var secondsToGo = this.roundUpToNearestMinute(Ember.get(this, 'secondsToGo'));
      var hours = Math.floor(secondsToGo / 3600);
      var minutes = Math.floor(secondsToGo % 3600 / 60);

      if (isNaN(hours) || isNaN(minutes)) {
        return '';
      }

      if (hours <= 0 && minutes <= 0) {
        window.clearInterval(Ember.get(this, 'intervalId'));
        return 'manual';
      } else {
        return this.formatTimer(hours, minutes, secondsToGo);
      }
    }),
    // Methods
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this.updateSecondsToGo();
      var intervalId = window.setInterval(function () {
        _this.updateSecondsToGo();
      }, 1000);
      this.set('intervalId', intervalId);
    },
    updateSecondsToGo: function updateSecondsToGo() {
      var _this2 = this;

      Ember.run.once(function () {
        var nowUnix = (0, _moment.default)().format('x');
        var endUnix = (0, _moment.default)(Ember.get(_this2, 'endTime')).format('x');
        var unixDiff = endUnix - nowUnix;
        var secondsToGo = Math.round(unixDiff / 1000);
        if (!_this2.isDestroyed) {
          _this2.set('secondsToGo', secondsToGo);
        }
      });
    },
    willDestroy: function willDestroy() {
      window.clearInterval(Ember.get(this, 'intervalId'));
    },

    roundUpToNearestMinute: function roundUpToNearestMinute(seconds) {
      return seconds + 59;
    },
    formatTimer: function formatTimer(hours, minutes, secondsToGo) {
      if (minutes < 10) {
        minutes = '0' + minutes;
      }
      if (secondsToGo < 60) {
        return '0:01';
      } else {
        return hours + ':' + minutes;
      }
      //return minutes + ':' + seconds;
      //return moment(get(this, 'endTime')).fromNow();
    }
  });
});