define('touch-portal/routes/internal/user/faults', ['exports', 'touch-portal/mixins/routes/authorisationable', 'touch-portal/mixins/routes/paginatable', 'touch-portal/mixins/routes/searchable', 'touch-portal/mixins/routes/sortable', 'ember-ux-date-sauce/utils/format-to-range', 'touch-portal/utils/remove-empty-attributes'], function (exports, _authorisationable, _paginatable, _searchable, _sortable, _formatToRange, _removeEmptyAttributes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authorisationable.default, _paginatable.default, _searchable.default, _sortable.default, {
    // Attributes
    ability: 'read historic-boiler-fault',
    queryParams: {
      type: {
        refreshModel: true
      },
      code: {
        refreshModel: true
      },
      range: {
        refreshModel: true
      }
    },
    // Methods
    getFilterParams: function getFilterParams(params) {
      if (!Ember.isEmpty(params.range)) {
        var range = (0, _formatToRange.default)(params.range);
        params.from = range[0].format('YYYY-MM-DD');
        params.to = range[1].format('YYYY-MM-DD');
        delete params.range;
      }
      if (!Ember.isEmpty(params.code)) {
        params.boilerFaultCode = params.code;
        delete params.code;
      }
      if (!Ember.isEmpty(params.type)) {
        params.faultType = params.type;
        delete params.type;
      }

      params = (0, _removeEmptyAttributes.default)(params);

      return params;
    },
    model: function model(params) {

      var user = this.modelFor('internal.user'),
          sort = this.getSortParam(params),
          page = this.getPageParams(params),
          filter = this.getFilterParams(params);

      filter.gatewayId = Ember.get(user, 'gateway.id');

      return this.store.query('historic-boiler-fault', {
        filter: filter,
        page: page,
        sort: sort,
        include: 'gateway'
      });
    }
  });
});