define('touch-portal/utils/const/link-redirect', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var SORT_OPTIONS = exports.SORT_OPTIONS = [{
    label: 'Key',
    value: 'key'
  }, {
    label: 'Url',
    value: 'redirectTo'
  }];
});