define('touch-portal/utils/const/execution', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var CANCEL_BOOST_EVENT_TYPE = exports.CANCEL_BOOST_EVENT_TYPE = 'cancel_boost';
  var OPEN_THERM_EVENT_TYPE = exports.OPEN_THERM_EVENT_TYPE = 'open_therm_passthrough';
  var OVERRIDE_EVENT_TYPE = exports.OVERRIDE_EVENT_TYPE = 'override';
  var SET_OPERATING_MODE_EVENT_TYPE = exports.SET_OPERATING_MODE_EVENT_TYPE = 'set_operating_mode';
  var EXECUTION_EVENT_TYPES = exports.EXECUTION_EVENT_TYPES = [CANCEL_BOOST_EVENT_TYPE, OPEN_THERM_EVENT_TYPE, OVERRIDE_EVENT_TYPE, SET_OPERATING_MODE_EVENT_TYPE];
  var COMPLETED_STATE = exports.COMPLETED_STATE = 'completed';
  var FAILED_STATE = exports.FAILED_STATE = 'failed';
  var RUNNING_STATE = exports.RUNNING_STATE = 'running';
  var QUEUED_STATE = exports.QUEUED_STATE = 'queued_server_side';
  var IN_PROGRESS_STATE = exports.IN_PROGRESS_STATE = 'in_progress';
  var NOT_TRANSMITTED_STATE = exports.NOT_TRANSMITTED_STATE = 'not_transmitted';

  var EXECUTION_STATES = exports.EXECUTION_STATES = [COMPLETED_STATE, FAILED_STATE, RUNNING_STATE, QUEUED_STATE, IN_PROGRESS_STATE, NOT_TRANSMITTED_STATE];

  var EXECUTION_STATE_OPTIONS = exports.EXECUTION_STATE_OPTIONS = [{
    value: COMPLETED_STATE,
    label: COMPLETED_STATE
  }, {
    value: FAILED_STATE,
    label: FAILED_STATE
  }, {
    value: RUNNING_STATE + ',' + QUEUED_STATE + ',' + IN_PROGRESS_STATE,
    label: RUNNING_STATE
  }, {
    value: NOT_TRANSMITTED_STATE,
    label: NOT_TRANSMITTED_STATE
  }];

  var EXECUTION_FAILURE_NONE = exports.EXECUTION_FAILURE_NONE = 'NO_FAILURE';
  var EXECUTION_FAILURE_APPLICATION = exports.EXECUTION_FAILURE_APPLICATION = 'APPLICATION_ERROR';
  var EXECUTION_FAILURE_TRANSPORT = exports.EXECUTION_FAILURE_TRANSPORT = 'TRANSPORT_ERROR';
  var EXECUTION_FAILURE_PROGRESS = exports.EXECUTION_FAILURE_PROGRESS = 'TIME_OUT_ON_COMMAND_PROGRESS';
  var EXECUTION_FAILURE_TRANSMITTED = exports.EXECUTION_FAILURE_TRANSMITTED = 'TIME_OUT_ON_TRANSMITTED_COMMAND';
  var EXECUTION_FAILURE_PEER = exports.EXECUTION_FAILURE_PEER = 'PEER_DOWN';
  var EXECUTION_FAILURE_TYPES = exports.EXECUTION_FAILURE_TYPES = [
  // EXECUTION_FAILURE_NONE,
  EXECUTION_FAILURE_APPLICATION, EXECUTION_FAILURE_TRANSPORT, EXECUTION_FAILURE_PROGRESS, EXECUTION_FAILURE_TRANSMITTED, EXECUTION_FAILURE_PEER];
  var SORT_OPTIONS = exports.SORT_OPTIONS = [{
    label: 'Time',
    value: 'insertedAt'
  }, {
    label: 'Type',
    value: 'eventType'
  }, {
    label: 'State',
    value: 'state'
  }, {
    label: 'Failure',
    value: 'failureCode'
  }];
});