define('touch-portal/components/uxs-date-range/year', ['exports', 'ember-ux-date-sauce/components/uxs-date-range/year'], function (exports, _year) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _year.default;
    }
  });
});