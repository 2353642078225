define('touch-portal/routes/internal/help-items/new', ['exports', 'touch-portal/mixins/routes/authorisationable', 'touch-portal/mixins/routes/rollbackable'], function (exports, _authorisationable, _rollbackable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authorisationable.default, _rollbackable.default, {
    // Attributes
    ability: 'create help-item',
    // Methods
    model: function model() {
      return this.store.createRecord('help-item');
    }
  });
});