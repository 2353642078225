define('touch-portal/models/gateway', ['exports', 'ember-data', 'touch-portal/mixins/validations/gateway'], function (exports, _emberData, _gateway) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany,
      Model = _emberData.default.Model;
  exports.default = Model.extend(_gateway.default, {
    // Attributes
    serial: attr('string'),
    addressLine1: attr('string'),
    addressLine2: attr('string'),
    town: attr('string'),
    county: attr('string'),
    postcode: attr('string'),
    status: attr('string'),
    lastSeen: attr('date'),
    devicesData: attr(),
    fullySetup: attr('boolean'),
    // Relationships
    user: belongsTo(),
    currentBoilerFaults: hasMany('historicBoilerFaults', {
      inverse: 'gateway'
    }),
    historicBoilerFaults: hasMany(),
    executions: hasMany()
  });
});