define('touch-portal/controllers/internal/user/resync', ['exports', 'touch-portal/utils/get-api-url'], function (exports, _getApiUrl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    // Service
    toast: Ember.inject.service(),
    // Attributes
    pageTitle: 'Resync Gateway',
    // Actions
    actions: {
      resync: function resync(model) {
        var toast = Ember.get(this, 'toast'),
            url = (0, _getApiUrl.default)('/gateway/' + model.get('gateway.id') + '/resync');

        Ember.$.post(url).done(Ember.run.bind(this, function () {
          toast.info('Gateway Resynced');
        })).fail(Ember.run.bind(this, function () {
          toast.info('Failed to resync, please try again!');
        }));
      },
      cancel: function cancel(model) {
        return this.transitionToRoute('internal.user.index', model.id);
      }
    }
  });
});