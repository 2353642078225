define('touch-portal/utils/get-api-url', ['exports', 'touch-portal/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = getApiUrl;
  function getApiUrl() {
    var path = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

    return path.indexOf('http') === 0 ? path : _environment.default.rootApiURL + '/' + _environment.default.apiNamespace + path;
  }
});