define('touch-portal/mirage/factories/user', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    firstName: function firstName() {
      return _emberCliMirage.faker.name.firstName();
    },
    lastName: function lastName() {
      return _emberCliMirage.faker.name.lastName();
    },
    name: function name() {
      return this.firstName + " " + this.lastName;
    },
    email: function email() {
      return _emberCliMirage.faker.internet.email();
    },
    role: function role() {
      return 'User';
    },
    lastLoggedInAt: function lastLoggedInAt() {
      return _emberCliMirage.faker.date.recent();
    },

    withGateway: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(user, server) {
        var gateway = server.create('gateway');
        user.update({
          gateway: gateway
        });
      }
    }),
    withGatewayWithCurrentFaults: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(user, server) {
        var faults = server.createList('boiler-fault', _emberCliMirage.faker.random.number({
          min: 0,
          max: 3
        })),
            gateway = server.create('gateway', {
          currentBoilerFaults: faults
        });

        user.update({
          gateway: gateway
        });
      }
    })
  });
});