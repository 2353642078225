define('touch-portal/utils/pad', ['exports', 'ember-pad/utils/pad'], function (exports, _pad) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _pad.default;
    }
  });
  Object.defineProperty(exports, 'padStart', {
    enumerable: true,
    get: function () {
      return _pad.padStart;
    }
  });
  Object.defineProperty(exports, 'padEnd', {
    enumerable: true,
    get: function () {
      return _pad.padEnd;
    }
  });
  Object.defineProperty(exports, 'padTpl', {
    enumerable: true,
    get: function () {
      return _pad.padTpl;
    }
  });
  Object.defineProperty(exports, 'padStartTpl', {
    enumerable: true,
    get: function () {
      return _pad.padStartTpl;
    }
  });
  Object.defineProperty(exports, 'padEndTpl', {
    enumerable: true,
    get: function () {
      return _pad.padEndTpl;
    }
  });
});