define('touch-portal/mirage/factories/gateway', ['exports', 'ember-cli-mirage', 'moment', 'touch-portal/utils/const/gateway'], function (exports, _emberCliMirage, _moment, _gateway) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    serial: function serial() {
      var p1 = _emberCliMirage.faker.random.number({
        min: 1000,
        max: 9999
      }),
          p2 = _emberCliMirage.faker.random.number({
        min: 1000,
        max: 9999
      }),
          p3 = _emberCliMirage.faker.random.number({
        min: 1000,
        max: 9999
      });

      return p1 + '-' + p2 + '-' + p3;
    },
    addressLine1: function addressLine1() {
      return _emberCliMirage.faker.address.streetName();
    },
    addressLine2: function addressLine2() {
      return _emberCliMirage.faker.address.secondaryAddress();
    },
    town: function town() {
      return _emberCliMirage.faker.address.city();
    },
    county: function county() {
      return _emberCliMirage.faker.address.county();
    },
    postcode: function postcode() {
      return _emberCliMirage.faker.address.zipCode();
    },
    fullySetup: function fullySetup() {
      return _emberCliMirage.faker.random.boolean();
    },
    lastSeen: function lastSeen() {
      return _emberCliMirage.faker.date.recent();
    },
    devicesData: function devicesData() {
      return {
        'optimisation-state': _emberCliMirage.faker.random.arrayElement(_gateway.OPTIMISATION_STATES),
        'hot-water-target-temperature	': _emberCliMirage.faker.random.number({
          min: 20,
          max: 60
        }),
        'hot-water-state': _emberCliMirage.faker.random.arrayElement(_gateway.HOT_WATER_STATES),
        'holiday-id	': _emberCliMirage.faker.random.number({
          min: 0,
          max: 1
        }),
        'holiday-ends-at': _emberCliMirage.faker.date.future(1),
        'gateway-state': _emberCliMirage.faker.random.arrayElement(_gateway.GATEWAY_STATES),
        'flame-symbol': _emberCliMirage.faker.random.boolean(),
        'current-outside-weather-type': _emberCliMirage.faker.random.arrayElement(Object.keys(_gateway.WEATHER_TYPES)),
        'current-outside-temperature': _emberCliMirage.faker.random.number({
          min: -5,
          max: 42
        }),
        'central-heating-timer-minutes': (0, _moment.default)(_emberCliMirage.faker.date.past()).format('HH:mm'),
        'central-heating-timer-ends': (0, _moment.default)(_emberCliMirage.faker.date.past()).format('HH:mm'),
        'central-heating-target-temperature': _emberCliMirage.faker.random.number({
          min: 15,
          max: 30
        }),
        'central-heating-state': _emberCliMirage.faker.random.arrayElement(_gateway.CENTRAL_HEATING_STATES),
        'central-heating-override-time-state': null,
        'central-heating-override-mode': null,
        'central-heating-next-period-target-temperature': _emberCliMirage.faker.random.number({
          min: 15,
          max: 30
        }),
        'central-heating-next-period-start': (0, _moment.default)(_emberCliMirage.faker.date.past()).format('HH:mm'),
        'central-heating-next-period-end': (0, _moment.default)(_emberCliMirage.faker.date.past()).format('HH:mm'),
        'central-heating-mode': _emberCliMirage.faker.random.arrayElement(_gateway.CENTRAL_HEATING_MODES),
        'central-heating-display-state': _emberCliMirage.faker.random.arrayElement(_gateway.CENTRAL_HEATING_STATES),
        'central-heating-display-mode': _emberCliMirage.faker.random.arrayElement(_gateway.CENTRAL_HEATING_MODES),
        'central-heating-current-temperature': _emberCliMirage.faker.random.number({
          min: 15,
          max: 30
        }),
        'central-heating-current-period-start': (0, _moment.default)(_emberCliMirage.faker.date.past()).format('HH:mm'),
        'central-heating-current-period-end': (0, _moment.default)(_emberCliMirage.faker.date.past()).format('HH:mm'),
        'central-heating-countdown-display-ends': (0, _moment.default)().add(_emberCliMirage.faker.random.number({
          max: 60 * 2
        }), 'minutes').format(),
        'central-heating-closest-scheduled-temperature': _emberCliMirage.faker.random.number({
          min: 15,
          max: 30
        }),
        'battery-state': _emberCliMirage.faker.random.arrayElement(_gateway.BATTERY_STATES),
        'gateway-firmware': "1.9.23"
      };
    }
  });
});