define("touch-portal/utils/const/historic-boiler-fault", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var HARDWARE_FAULT = exports.HARDWARE_FAULT = "hardware fault";
  var BOILER_FAULT = exports.BOILER_FAULT = "boiler fault";
  var OVERKIZ_FAULT = exports.OVERKIZ_FAULT = "overkiz execution";
  var GATEWAY_DOWN_FAULT = exports.GATEWAY_DOWN_FAULT = "gateway down";
  var BOILER_FAULT_TYPES = exports.BOILER_FAULT_TYPES = [HARDWARE_FAULT, BOILER_FAULT, OVERKIZ_FAULT, GATEWAY_DOWN_FAULT];
  var SORT_OPTIONS = exports.SORT_OPTIONS = [{
    label: 'Time',
    value: 'insertedAt'
  }, {
    label: 'Type',
    value: 'faultType'
  }, {
    label: 'Code',
    value: 'boilerFaultCode'
  }];
});