define('touch-portal/mirage/factories/basic-system-info', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    registeredUsersCount: function registeredUsersCount() {
      return 1232;
    },
    onlineGatewaysCount: function onlineGatewaysCount() {
      return 983;
    },
    offlineGatewaysCount: function offlineGatewaysCount() {
      return 12;
    }
  });
});