define('touch-portal/utils/const/gateway', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var BATTERY_STATES = exports.BATTERY_STATES = ['normal', 'low', 'very-low'];
  var CENTRAL_HEATING_STATES = exports.CENTRAL_HEATING_STATES = ['on', 'off', 'auto'];
  var CENTRAL_HEATING_MODES = exports.CENTRAL_HEATING_MODES = ['manual', 'auto', 'timer', 'next'];
  var HOT_WATER_STATES = exports.HOT_WATER_STATES = ['on', 'off', 'auto'];
  var OPTIMISATION_STATES = exports.OPTIMISATION_STATES = ['on', 'off'];
  var GATEWAY_STATES = exports.GATEWAY_STATES = ['alive', 'down'];
  var WEATHER_TYPES = exports.WEATHER_TYPES = {
    'NA': 'Not available',
    '0': 'Clear night',
    '1': 'Sunny day',
    '2': 'Partly cloudy (night)',
    '3': 'Partly cloudy (day)',
    '4': 'Not used',
    '5': 'Mist',
    '6': 'Fog',
    '7': 'Cloudy',
    '8': 'Overcast',
    '9': 'Light rain shower (night)',
    '10': 'Light rain shower (day)',
    '11': 'Drizzle',
    '12': 'Light rain',
    '13': 'Heavy rain shower (night)',
    '14': 'Heavy rain shower (day)',
    '15': 'Heavy rain',
    '16': 'Sleet shower (night)',
    '17': 'Sleet shower (day)',
    '18': 'Sleet',
    '19': 'Hail shower (night)',
    '20': 'Hail shower (day)',
    '21': 'Hail',
    '22': 'Light snow shower (night)',
    '23': 'Light snow shower (day)',
    '24': 'Light snow',
    '25': 'Heavy snow shower (night)',
    '26': 'Heavy snow shower (day)',
    '27': 'Heavy snow',
    '28': 'Thunder shower (night)',
    '29': 'Thunder shower (day)',
    '30': 'Thunder'
  };
  var WEATHER_ICONS = exports.WEATHER_ICONS = {
    'NA': 'not-available',
    '0': 'moon', // 'Clear night',
    '1': 'sun', // 'Sunny day',
    '2': 'cloud', // 'Partly cloudy (night)',
    '3': 'cloud', // 'Partly cloudy (day)',
    '4': 'not-available', // 'Not used',
    '5': 'wind', // 'Mist',
    '6': 'wind', // 'Fog',
    '7': 'cloud', // 'Cloudy',
    '8': 'wind', // 'Overcast',
    '9': 'not-available', // 'Light rain shower (night)',
    '10': 'rain', // 'Light rain shower (day)',
    '11': 'rain', // 'Drizzle',
    '12': 'rain', // 'Light rain',
    '13': 'rain', // 'Heavy rain shower (night)',
    '14': 'rain', // 'Heavy rain shower (day)',
    '15': 'rain', // 'Heavy rain',
    '16': 'rain', // 'Sleet shower (night)',
    '17': 'rain', // 'Sleet shower (day)',
    '18': 'rain', // 'Sleet',
    '19': 'rain', // 'Hail shower (night)',
    '20': 'rain', // 'Hail shower (day)',
    '21': 'rain', // 'Hail',
    '22': 'snow', // 'Light snow shower (night)',
    '23': 'snow', // 'Light snow shower (day)',
    '24': 'snow', // 'Light snow',
    '25': 'snow', // 'Heavy snow shower (night)',
    '26': 'snow', // 'Heavy snow shower (day)',
    '27': 'snow', // 'Heavy snow',
    '28': 'lightning', // 'Thunder shower (night)',
    '29': 'lightning', // 'Thunder shower (day)',
    '30': 'lightning' // 'Thunder',
  };
});