define('touch-portal/mixins/routes/authorisationable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    // Services
    can: Ember.inject.service(),
    // Methods
    beforeModel: function beforeModel() {
      var result = this._super.apply(this, arguments),
          ability = Ember.get(this, 'ability'),
          can = Ember.get(this, 'can');

      if (Ember.isEmpty(ability)) {
        Ember.Logger.error('Set an ability on authorisationable route: ' + this.routeName);
      } else {
        if (can.cannot(ability)) {
          Ember.Logger.info('Authorisationable route ability "' + ability + '" not met on: ' + this.routeName + ' user ' + Ember.get(this, 'session.currentUser.firstName') + ' is: ' + Ember.get(this, 'session.currentUser.role'));
          return this.transitionTo('internal.dashboard');
        }
      }

      return result;
    }
  });
});