define('touch-portal/components/confirmation-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // Attributes
    confirmText: "Confirm",
    cancelText: "Cancel",
    tagName: '',
    // Actions
    actions: {
      confirm: function confirm() {
        var action = Ember.get(this, "onSubmit");
        if (action) {
          return action();
        }
      },
      cancel: function cancel() {
        var action = Ember.get(this, "onCancel");
        if (action) {
          return action();
        }
      }
    }
  });
});