define('touch-portal/controllers/internal/link-mapper-error', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    // Attributes
    singular: 'Link Map',
    // Computed
    status: Ember.computed.alias('model.errors.firstObject.status'),
    pageTitle: Ember.computed('status', function () {
      switch (Ember.get(this, 'status')) {
        case '404':
          return 'Not Found';
        case '500':
        default:
          return 'Server Error';
      }
    }),
    heading: Ember.computed('status', 'singular', function () {

      switch (Ember.get(this, 'status')) {
        case '404':
          return 'Could not find a ' + Ember.get(this, 'singular') + ' with that id.';
        case '500':
        default:
          return 'There was a problem on the server loading the ' + Ember.get(this, 'singular');
      }
    })
  });
});