define('touch-portal/utils/temp-to-class-modifier', ['exports', 'touch-portal/utils/const/temperature'], function (exports, _temperature) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = tempToClassModifier;
  function tempToClassModifier(temperature) {

    if (temperature) {

      temperature = parseFloat(temperature);

      if (temperature >= _temperature.HOT_TEMPERATURE_THRESHOLD) {
        return 'hot';
      } else if (temperature >= _temperature.WARM_TEMPERATURE_THRESHOLD) {
        return 'warm';
      } else {
        return 'cool';
      }
    }
    return 'off';
  }
});