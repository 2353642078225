define('touch-portal/controllers/internal/users/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    // Service
    toast: Ember.inject.service(),
    // Attributes
    pageTitle: "New Customer",
    // Actions
    actions: {
      submit: function submit(model) {
        var _this = this;

        var toast = Ember.get(this, 'toast');

        model.save().then(function () {
          toast.info('Customer Created');
          _this.transitionToRoute('internal.users.index');
        }, function () {
          toast.info('Server Error');
        });
      },
      invalid: function invalid() /*model, errors*/{
        var toast = Ember.get(this, 'toast');
        toast.info('Please correct form errors');
      }
    }
  });
});