define('touch-portal/mirage/factories/portal-user', ['exports', 'ember-cli-mirage', 'touch-portal/utils/const/portal-user'], function (exports, _emberCliMirage, _portalUser) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    firstName: function firstName() {
      return _emberCliMirage.faker.name.firstName();
    },
    lastName: function lastName() {
      return _emberCliMirage.faker.name.lastName();
    },
    name: function name() {
      return this.firstName + " " + this.lastName;
    },
    email: function email() {
      return _emberCliMirage.faker.internet.email();
    },
    role: function role() {
      return _emberCliMirage.faker.random.arrayElement(_portalUser.ROLE_OPTIONS);
    }
  });
});