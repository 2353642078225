define('touch-portal/mirage/factories/execution', ['exports', 'ember-cli-mirage', 'touch-portal/utils/const/execution'], function (exports, _emberCliMirage, _execution) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    execId: function execId() {
      return _emberCliMirage.faker.random.uuid();
    },
    eventType: function eventType() {
      return _emberCliMirage.faker.random.arrayElement(_execution.EXECUTION_EVENT_TYPES);
    },
    state: function state() {
      return _emberCliMirage.faker.random.arrayElement(_execution.EXECUTION_STATES);
    },

    failureCode: 0,
    failureType: _execution.EXECUTION_FAILURE_NONE,
    insertedAt: function insertedAt() {
      return _emberCliMirage.faker.date.recent(5);
    },
    updatedAt: function updatedAt() {
      return _emberCliMirage.faker.date.recent(5);
    },

    asCompletedExecution: (0, _emberCliMirage.trait)({
      state: _execution.COMPLETED_STATE
    }),
    asFailedExecution: (0, _emberCliMirage.trait)({
      state: _execution.FAILED_STATE,
      failureCode: function failureCode() {
        return _emberCliMirage.faker.random.number({
          min: 1,
          max: 10
        });
      },
      failureType: function failureType() {
        return _emberCliMirage.faker.random.arrayElement(_execution.EXECUTION_FAILURE_TYPES);
      }
    }),
    asRunningExecution: (0, _emberCliMirage.trait)({
      state: _execution.RUNNING_STATE
    }),
    withGateway: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(fault, server) {
        var gateways = server.schema.gateways.all().models,
            gateway = _emberCliMirage.faker.random.arrayElement(gateways);

        fault.update({
          gateway: gateway
        });
      }
    })
  });
});