define('touch-portal/helpers/pad-start', ['exports', 'ember-pad/helpers/pad-start'], function (exports, _padStart) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _padStart.default;
    }
  });
  Object.defineProperty(exports, 'padStart', {
    enumerable: true,
    get: function () {
      return _padStart.padStart;
    }
  });
});