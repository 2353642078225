define('touch-portal/mirage/helpers/responses', ['exports', 'ember-cli-mirage/response'], function (exports, _response) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.errorResponse = errorResponse;
  exports.successResponse = successResponse;
  function errorResponse(errors) {
    return new _response.default(422, {
      'Content-Type': 'application/vnd.api+json'
    }, {
      errors: errors
    });
  }

  function successResponse(status, data) {
    return new _response.default(status, {
      'Content-Type': 'application/vnd.api+json'
    }, data);
  }
});