define('touch-portal/models/link-redirect', ['exports', 'ember-data', 'touch-portal/mixins/validations/link-redirect'], function (exports, _emberData, _linkRedirect) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      Model = _emberData.default.Model;
  exports.default = Model.extend(_linkRedirect.default, {
    key: attr('string'),
    redirectTo: attr('string')
  });
});