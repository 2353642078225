define('touch-portal/components/sort-dropdown', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    icon: 'swap_vert',
    subheading: 'Sort by',
    tagName: '',
    // Actions
    actions: {
      onItemSelect: function onItemSelect(value) {
        var action = Ember.get(this, 'onSelect');
        return action(value);
      }
    }
  });
});