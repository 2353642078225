define('touch-portal/components/user-actions', ['exports', 'ember-can'], function (exports, _emberCan) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_emberCan.CanMixin, {
    // Attributes
    tagName: '',
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      Ember.set(this, '_items', [{
        title: 'View Customer',
        route: 'internal.user.index',
        ability: 'read user'
      }, {
        title: 'View Gateway',
        route: 'internal.user.gateway',
        requiresGateway: true,
        ability: 'read gateway'
      }, {
        title: 'Impersonate Customer',
        route: 'internal.user.impersonate',
        requiresGateway: true,
        ability: 'impersonate user'
      }, {
        title: 'Boiler Faults',
        route: 'internal.user.faults',
        requiresGateway: true,
        ability: 'read historic-boiler-fault'
      }, {
        title: 'Gateway Executions',
        route: 'internal.user.executions',
        requiresGateway: true,
        ability: 'read execution'
      }, {
        title: 'Edit Customer',
        route: 'internal.user.edit',
        ability: 'update user'
      }, {
        title: 'Refresh Gateway Devices',
        route: 'internal.user.refresh-devices',
        requiresGateway: true,
        ability: 'refresh gateway'
      }, {
        title: 'Resync Gateway',
        route: 'internal.user.resync',
        requiresGateway: true,
        ability: 'resync gateway'
      }, {
        title: 'Deregister Gateway',
        route: 'internal.user.deregister',
        requiresGateway: true,
        ability: 'deregister gateway'
      }, {
        title: 'Archive Customer',
        route: 'internal.user.archive',
        ability: 'archive user'
      }]);
    },

    // Computed
    items: Ember.computed('_items', 'model.gateway.id', function () {
      var _this = this;

      var items = Ember.get(this, '_items'),
          hasGateway = !!Ember.get(this, 'model.gateway.id'),
          array = [];

      items.forEach(function (_item) {
        var item = Ember.copy(_item),
            ability = Ember.get(item, 'ability'),
            requiresGateway = Ember.get(_item, 'requiresGateway');

        item.disabled = false;

        if (requiresGateway) {
          if (!hasGateway) {
            item.disabled = true;
          }
        }

        if (ability) {
          if (_this.can(Ember.get(item, 'ability'))) {
            array.pushObject(item);
          }
        } else {
          array.pushObject(item);
        }
      });

      return array;
    })
  });
});