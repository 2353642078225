define('touch-portal/mirage/models/gateway', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Model.extend({
    user: (0, _emberCliMirage.belongsTo)(),
    boilerFaults: (0, _emberCliMirage.hasMany)(),
    currentBoilerFaults: (0, _emberCliMirage.hasMany)('boilerFaults', {
      inverse: 'gateway'
    }),
    executions: (0, _emberCliMirage.hasMany)()
  });
});