define('touch-portal/routes/internal/link-mappers/index', ['exports', 'touch-portal/mixins/routes/authorisationable', 'touch-portal/mixins/routes/paginatable', 'touch-portal/mixins/routes/searchable', 'touch-portal/mixins/routes/sortable'], function (exports, _authorisationable, _paginatable, _searchable, _sortable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authorisationable.default, _paginatable.default, _searchable.default, _sortable.default, {
    // Attributes
    ability: 'read link-map',
    // Methods
    model: function model(params) {
      var sort = this.getSortParam(params),
          page = this.getPageParams(params);

      return this.store.query('link-redirect', {
        filter: params,
        page: page,
        sort: sort
      });
    }
  });
});