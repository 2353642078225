define('touch-portal/models/recover-password', ['exports', 'ember-data', 'touch-portal/mixins/validations/recover-password'], function (exports, _emberData, _recoverPassword) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      Model = _emberData.default.Model;
  exports.default = Model.extend(_recoverPassword.default, {
    email: attr('string'),
    recoveryEmailSent: attr('boolean')
  });
});