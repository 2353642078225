define('touch-portal/helpers/weather-to-icon', ['exports', 'touch-portal/utils/const/gateway'], function (exports, _gateway) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.weatherToHuman = weatherToHuman;
  function weatherToHuman(params /*, hash*/) {
    var index = params[0] + "";
    if (index) {
      var icon = Ember.get(_gateway.WEATHER_ICONS, index);
      if (icon) {
        return icon;
      }
    }
    return Ember.get(_gateway.WEATHER_ICONS, 'NA');
  }

  exports.default = Ember.Helper.helper(weatherToHuman);
});