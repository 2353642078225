define('touch-portal/models/user', ['exports', 'ember-data', 'touch-portal/mixins/validations/user'], function (exports, _emberData, _user) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      Model = _emberData.default.Model;
  exports.default = Model.extend(_user.default, {
    // Attributes
    email: attr('string'),
    firstName: attr('string'),
    lastName: attr('string'),
    role: attr('string'),
    lastLoggedInAt: attr('date'),
    // Relationships
    gateway: belongsTo(),
    // Computed
    name: Ember.computed('firstName', 'lastName', function () {
      var firstName = Ember.get(this, 'firstName'),
          lastName = Ember.get(this, 'lastName'),
          name = [];
      if (firstName) {
        name.pushObject(firstName);
      }
      if (lastName) {
        name.pushObject(lastName);
      }
      return name.join(' ');
    }),
    avatar: Ember.computed(function () {
      return 'http://api.faceholder.io/faces?id=' + Ember.get(this, 'id');
    })
  });
});