define('touch-portal/routes/internal/user/edit', ['exports', 'touch-portal/mixins/routes/authorisationable', 'touch-portal/mixins/routes/rollbackable'], function (exports, _authorisationable, _rollbackable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authorisationable.default, _rollbackable.default, {
    // Attributes
    ability: 'update user'
  });
});