define('touch-portal/components/portal-user-actions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // Attributes
    tagName: '',
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      Ember.set(this, 'items', [{
        title: 'View User',
        route: 'internal.portal-user.index'
      }, {
        title: 'Edit User',
        route: 'internal.portal-user.edit'
      }, {
        title: 'Archive User',
        route: 'internal.portal-user.archive'
      }]);
    }
  });
});