define('touch-portal/helpers/temp-to-class-modifier', ['exports', 'touch-portal/utils/temp-to-class-modifier'], function (exports, _tempToClassModifier) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.tempToClassModifier = tempToClassModifier;
  function tempToClassModifier(params /*, hash*/) {
    return (0, _tempToClassModifier.default)(params[0]);
  }

  exports.default = Ember.Helper.helper(tempToClassModifier);
});