define('touch-portal/mirage/serializers/application', ['exports', 'ember-mirage-sauce/mirage-serializers/json-api-serializer'], function (exports, _jsonApiSerializer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _jsonApiSerializer.default.extend({
    /**
      Paginate response
       @access protected
      @method paginate
      @param {object} results data to be paginated
      @param {number} page  current page
      @param {number} size  current page size
      @return {object}
     */
    paginate: function paginate(res, page, size) {
      var slicedResults = function slicedResults(results) {
        var start = (page - 1) * size;
        var end = start + size;

        return results.slice(start, end);
      };

      var buildMetadata = function buildMetadata(results) {
        return {
          number: page,
          size: size,
          total: results.length,
          pages: Math.floor(results.length / size)
        };
      };

      res.meta = buildMetadata(res.data);
      res.data = slicedResults(res.data);

      return res;
    }
  });
});