define('touch-portal/controllers/internal/help-items/index', ['exports', 'touch-portal/mixins/controllers/paginatable', 'touch-portal/mixins/controllers/searchable', 'touch-portal/mixins/controllers/sortable', 'touch-portal/utils/const/help-item'], function (exports, _paginatable, _searchable, _sortable, _helpItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_paginatable.default, _searchable.default, _sortable.default, {
    pageTitle: 'Help',
    queryParams: ['role'],
    role: null,
    sort: 'title',
    sortOptions: _helpItem.SORT_OPTIONS
  });
});