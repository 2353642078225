define('touch-portal/mirage/factories/link-redirect', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    key: function key() {
      return _emberCliMirage.faker.lorem.word();
    },
    redirectTo: function redirectTo() {
      return _emberCliMirage.faker.internet.url();
    }
  });
});