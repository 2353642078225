define('touch-portal/utils/remove-empty-attributes', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = removeEmptyAttributes;
  function removeEmptyAttributes(obj) {
    for (var k in obj) {
      if (Ember.isEmpty(obj[k])) {
        delete obj[k];
      }
    }
    return obj;
  }
});