define('touch-portal/transitions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {

    // Transition for filter forms
    this.transition(this.hasClass('liquid-from-right'), this.toValue(true), this.use('customToLeft'), this.reverse('customToRight'));

    /**
     * AUTH TRANSITIONS
     */
    this.transition(this.fromRoute('external'), this.toRoute('internal'), this.use('customToUp'), this.reverse('customToDown'));
    this.transition(this.fromRoute('external.login'), this.toRoute('external.recover-password'), this.use('customToUp'), this.reverse('customToDown'));
    this.transition(this.fromRoute('external.login'), this.toRoute('external.reset-password'), this.use('customToUp'), this.reverse('customToDown'));
    this.transition(this.toRoute('internal.logout'), this.use('fade'), this.reverse('fade'));

    /**
     * DASHBOARD TRANSITIONS
     */
    this.transition(this.fromRoute('internal.dashboard'), this.toRoute(['internal.portal-users', 'internal.account']), this.use('customToUp'), this.reverse('customToDown'));

    /**
     * ACCOUNT TRANSITIONS
     */
    this.transition(this.fromRoute('internal.account.index'), this.toRoute('internal.account.password'), this.use('fade'), this.reverse('fade'));

    /**
     * CUSTOMER TRANSITIONS
     */
    this.transition(this.fromRoute('internal.users.index'), this.toRoute('internal.users.new'), this.use('customToLeft'), this.reverse('customToRight'));

    this.transition(this.fromRoute('internal.users'), this.toRoute('internal.user'), this.use('customToLeft'), this.reverse('customToRight'));

    this.transition(this.fromRoute(['internal.user.index', 'internal.user.faults', 'internal.user.executions', 'internal.user.edit', 'internal.user.deregister', 'internal.user.archive']), this.toRoute(['internal.user.index', 'internal.user.faults', 'internal.user.executions', 'internal.user.edit', 'internal.user.deregister', 'internal.user.archive']), this.use('fade'), this.reverse('fade'));

    /**
     * USER TRANSITIONS
     */
    this.transition(this.fromRoute('internal.portal-users.index'), this.toRoute('internal.portal-users.new'), this.use('customToLeft'), this.reverse('customToRight'));

    this.transition(this.fromRoute('internal.portal-users'), this.toRoute('internal.portal-user'), this.use('customToLeft'), this.reverse('customToRight'));

    this.transition(this.fromRoute(['internal.portal-user.index', 'internal.portal-user.edit', 'internal.portal-user.archive']), this.toRoute(['internal.portal-user.index', 'internal.portal-user.edit', 'internal.portal-user.archive']), this.use('fade'), this.reverse('fade'));
  };
});