define('touch-portal/mixins/validations/help-item', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = (0, _emberCpValidations.buildValidations)({
    title: [(0, _emberCpValidations.validator)('presence', true)],
    video: [(0, _emberCpValidations.validator)('format', {
      allowBlank: true,
      type: 'url'
    })],
    link: [(0, _emberCpValidations.validator)('format', {
      allowBlank: true,
      type: 'url'
    })]
  });
});