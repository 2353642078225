define('touch-portal/models/execution', ['exports', 'ember-data', 'touch-portal/utils/const/execution'], function (exports, _emberData, _execution) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      Model = _emberData.default.Model;
  exports.default = Model.extend({
    // Attributes
    execId: attr('string'),
    eventType: attr('string'),
    state: attr('string'),
    failureCode: attr('number'),
    failureType: attr('string'),
    insertedAt: attr('date'),
    updatedAt: attr('date'),
    // Relationships
    gateway: belongsTo('gateway'),
    // Computed
    stateStyle: Ember.computed('state', function () {
      var type = Ember.get(this, 'state');

      if (type === _execution.COMPLETED_STATE) {
        return 'success';
      }
      if (type === _execution.FAILED_STATE) {
        return 'error';
      }
      if (type === _execution.RUNNING_STATE) {
        return 'primary';
      }
    }),
    isFailure: Ember.computed.equal('state', _execution.FAILED_STATE),
    isCompleted: Ember.computed.equal('state', _execution.COMPLETED_STATE),
    isRunning: Ember.computed.or('state', _execution.RUNNING_STATE, _execution.QUEUED_STATE, _execution.IN_PROGRESS_STATE),
    isNotTransmitted: Ember.computed.equal('state', _execution.NOT_TRANSMITTED_STATE)
  });
});