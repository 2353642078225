define('touch-portal/controllers/internal/dashboard', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ONLINE = 'online';
  var OFFLINE = 'offline';

  exports.default = Ember.Controller.extend({
    // Services
    toast: Ember.inject.service(),
    store: Ember.inject.service(),
    // Computed
    status: Ember.computed(function () {
      return {
        overkiz: {
          now: this.genRandomUptime(0.25),
          history: this.genRandomUptimeHistory(60)
        },
        touch: {
          now: this.genRandomUptime(0.25),
          history: this.genRandomUptimeHistory(60)
        },
        app: {
          now: this.genRandomUptime(0.25),
          history: this.genRandomUptimeHistory(60)
        }
      };
    }),
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      Ember.set(this, 'gatewayModel', {
        gateway: null
      });
      Ember.set(this, 'userModel', {
        search: null
      });
      Ember.set(this, 'postcodeModel', {
        postcode: null
      });
    },
    genRandomUptimeHistory: function genRandomUptimeHistory(days) {
      var array = [];
      for (var i = 0; i < days; i++) {
        array.pushObject(this.genRandomUptime(0.1));
      }
      return array;
    },
    genRandomUptime: function genRandomUptime(chance) {
      if (Math.random() < chance) {
        return OFFLINE;
      } else {
        return ONLINE;
      }
    },

    // Attributes
    actions: {
      searchGateway: function searchGateway(gateway) {
        var _this = this;

        var toast = Ember.get(this, 'toast'),
            serial = Ember.get(gateway, 'serial');

        this.store.query('user', {
          filter: {
            serial: serial
          },
          include: 'gateway'
        }).then(function (results) {
          var numResults = Ember.get(results, 'length');

          if (numResults > 1) {
            // Redirect to index route filtered by gateway
            return _this.transitionToRoute('internal.users', {
              queryParams: {
                gateway: serial
              }
            });
          } else if (numResults === 1) {
            // Redirect to gateway route of the found instance
            var result = Ember.get(results, 'firstObject');

            return _this.transitionToRoute('internal.user.gateway', Ember.get(result, 'id'));
          } else {
            // Nada Surf
            toast.warning('No Gateways Found');
          }
        }, function () {
          toast.error('Server Error');
        });
      },
      findGatewayInvalid: function findGatewayInvalid() /*errors*/{
        var toast = Ember.get(this, 'toast');

        toast.error('Please Enter a Valid Gateway Pin');
      },
      searchUser: function searchUser(user) {
        var _this2 = this;

        var toast = Ember.get(this, 'toast'),
            search = Ember.get(user, 'search');

        this.store.query('user', {
          filter: {
            search: search
          }
        }).then(function (results) {
          var numResults = Ember.get(results, 'length');
          if (numResults > 1) {

            // Redirect to index route filtered by gateway
            return _this2.transitionToRoute('internal.users', {
              queryParams: {
                search: search
              }
            });
          } else if (numResults === 1) {

            // Redirect to gateway route of the found instance
            var result = Ember.get(results, 'firstObject');
            return _this2.transitionToRoute('internal.user.index', Ember.get(result, 'id'));
          } else {
            // Nada Surf
            toast.warning('No Users Found');
          }
        }, function () {
          toast.error('Server Error');
        });
      },
      findUserInvalid: function findUserInvalid() /*errors*/{
        var toast = Ember.get(this, 'toast');

        toast.error('Please Enter a Name to Search');
      },
      searchPostcode: function searchPostcode(gateway) {
        var _this3 = this;

        var toast = Ember.get(this, 'toast'),
            postcode = Ember.get(gateway, 'postcode');

        this.store.query('user', {
          filter: {
            postcode: postcode
          },
          include: 'gateway'
        }).then(function (results) {
          var numResults = Ember.get(results, 'length');

          if (numResults > 1) {
            // Redirect to index route filtered by gateway
            return _this3.transitionToRoute('internal.users', {
              queryParams: {
                postcode: postcode
              }
            });
          } else if (numResults === 1) {
            // Redirect to gateway route of the found instance
            var result = Ember.get(results, 'firstObject');

            return _this3.transitionToRoute('internal.user', Ember.get(result, 'id'));
          } else {
            // Nada Surf
            toast.warning('No Gateways Found');
          }
        }, function () {
          toast.error('Server Error');
        });
      },
      findGatewayByPostcodeInvalid: function findGatewayByPostcodeInvalid() /*errors*/{
        var toast = Ember.get(this, 'toast');

        toast.error('Please Enter A Valid Postcode');
      },
      resetGateway: function resetGateway(gateway) {
        var toast = Ember.get(this, 'toast'),
            data = {
          serial: Ember.get(gateway, 'serial')
        },
            url = getAPIURL('/gateway/reset');

        $.ajax({
          type: 'POST',
          url: url,
          accept: 'application/vnd.api+json',
          contentType: 'application/vnd.api+json',
          data: data,
          success: function success() {
            toast.success('Gateway Reset');
          },
          error: function error() /*jqXHR, textStatus*/{
            toast.error('Server Error');
          }
        });
      },
      resetGatewayInvalid: function resetGatewayInvalid() /*errors*/{
        var toast = Ember.get(this, 'toast');
        toast.error('Please Enter the Full Gateway Pin');
      }
    }
  });
});