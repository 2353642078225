define('touch-portal/components/link-mapper-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // Attributes
    tagName: '',
    // Actions
    actions: {
      submit: function submit() /*model*/{
        var action = Ember.get(this, 'onSubmit');
        if (action) {
          return action();
        }
      },
      invalid: function invalid() /*model, errors*/{
        var action = Ember.get(this, 'onInvalid');
        if (action) {
          return action();
        }
      }
    }
  });
});