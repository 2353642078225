define('touch-portal/routes/internal/link-mappers/new', ['exports', 'touch-portal/mixins/routes/rollbackable', 'touch-portal/mixins/routes/authorisationable'], function (exports, _rollbackable, _authorisationable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authorisationable.default, _rollbackable.default, {
    // Attributes
    ability: 'create link-map',
    // Methods
    model: function model() {
      return this.store.createRecord('link-redirect');
    }
  });
});