define('touch-portal/routes/internal/link-mapper', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    // Methods
    model: function model(params) {
      return this.store.findRecord('link-redirect', params.link_redirect_id);
    },

    // Actions
    actions: {
      error: function error() /*error, transition*/{

        // const status = get(error, 'errors.firstObject.status');
        //
        // if (status === "404") {
        //   return true;
        // }
        return true;
      }
    }
  });
});