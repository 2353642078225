define('touch-portal/mirage/helpers/invite-handlers', ['exports', 'touch-portal/mirage/helpers/responses'], function (exports, _responses) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.putInvite = putInvite;
  function putInvite(db, request) {

    var body = JSON.parse(request.requestBody),
        errors = [];

    if (body.data.attributes.token === 'badtoken') {
      errors.push({
        status: "422",
        source: {
          pointer: "/data/attributes/badtoken"
        },
        title: "Invalid Attribute",
        detail: "Token is invalid."
      });
      return (0, _responses.errorResponse)(errors);
    }

    return (0, _responses.successResponse)(204);
  }
});