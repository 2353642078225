define('touch-portal/controllers/internal/portal-user/archive', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    // Service
    toast: Ember.inject.service(),
    // Attributes
    pageTitle: 'Archive Ideal User',
    // Actions
    actions: {
      archive: function archive(model) {
        var _this = this;

        var toast = Ember.get(this, 'toast');

        model.deleteRecord();
        model.save().then(function () {
          toast.info('Ideal User Archived');
          return _this.transitionToRoute('internal.portal-users');
        }, function () {
          return toast.info('Server Error');
        });
      },
      cancel: function cancel(model) {
        return this.transitionToRoute('internal.portal-user.index', model.id);
      }
    }
  });
});